import useIsMobile from "@/hooks/useIsMobile";
import { isGroupItem, useNav } from "@/hooks/useNavs";
import { Image, Box, Flex, Menu, Link, useDisclosure } from "@chakra-ui/react";
import { FC, useRef } from "react";
import NavLink from "../NavLink";
import HeaderProfileMenu from "./HeaderProfileMenu";
import { route } from "nextjs-routes";

export const Header: FC = () => {
  const isMobile = useIsMobile();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const containerRef = useRef(null);
  const { navItems } = useNav();

  const renderNavigationContent = (
    <>
      {navItems.map((item, index) => {
        return (
          <Box
            _first={{ mt: { base: "2rem", lg: "unset" } }}
            _last={{ mb: { base: "2rem", lg: "unset" } }}
            key={index}
          >
            {!isGroupItem(item) && (
              <Box key={`menu-item-${Math.random().toString()}`}>
                <Menu>
                  <NavLink
                    w="100%"
                    item={item}
                    justifyContent="center"
                    fontSize={{ base: "40px", lg: "16px" }}
                    color={
                      (item as any)?.isActive
                        ? "neutral.element.primary"
                        : "neutral.element.tertiary2"
                    }
                    onClick={() => {
                      if (isMobile) {
                        onToggle();
                      }
                    }}
                  />
                </Menu>
              </Box>
            )}
          </Box>
        );
      })}
    </>
  );

  return (
    <Box
      ref={containerRef}
      zIndex={999}
      as="header"
      bgColor="neutral.on.surface.1"
      transitionProperty="box-shadow"
      transitionDuration="slow"
      width="full"
      id="header"
      position="relative"
      alignItems="stretch"
      boxShadow="lg"
      borderBottom={isOpen ? "1px solid" : "none"}
    >
      <Flex
        alignItems="center"
        gap={4}
        justifyContent="space-between"
        py="12px"
        mx="auto"
        px={{ base: "16px", lg: "24px", xl: "40px" }}
        w={{ base: "full", xl: "1366px" }}
      >
        <Flex alignItems="center" gap="60px">
          <Link href={route({ pathname: "/" })}>
            <Image
              src="/icons/logo-light.svg"
              alt="Pit finance"
              width=""
              height="auto"
            />
          </Link>
          <Flex display={{ base: "none", lg: "flex" }} gap="32px">
            {renderNavigationContent}
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          float="right"
          gap={{ base: "12px", lg: 5, xl: 8 }}
        >
          <Flex
            order={1}
            alignItems="center"
            gap={{ base: 2, lg: 5, xl: 8 }}
            float="right"
          >
            <Flex
              left={0}
              top="61px"
              zIndex={1001}
              height={{
                base: isOpen ? `calc(100dvh - 61px)` : "0px",
                lg: "unset",
              }}
              gap={{ base: 1, lg: 5, xl: 8 }}
              transition="height 0.3s ease-in-out !important"
              paddingX={{ base: 4, lg: "unset" }}
              onClick={(e) => e.stopPropagation()}
              width={{ base: "full", lg: "unset" }}
              boxShadow={{ base: "md", lg: "none" }}
              overflowY={{ base: "auto", lg: "unset" }}
              position={{ base: "absolute", lg: "static" }}
              flexDirection={{ base: "column", lg: "row" }}
              alignItems={{ base: "stretch", lg: "center" }}
              sx={{ transform: { lg: "translate(0, 0) !important" } }}
              justifyContent={{ base: "flex-start", lg: "flex-end" }}
              maxHeight={{ lg: "2.25rem !important" }}
              display={{ base: "initial", lg: "none" }}
              bgColor="neutral.surface"
            >
              {renderNavigationContent}
            </Flex>
          </Flex>
          <Box position="relative" id="zzz" order={3}>
            <HeaderProfileMenu
              isOpenDropdown={isOpen}
              onClick={onClose}
              onToggleDropdown={() => onToggle()}
            />
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

import { Link, LinkProps, chakra, shouldForwardProp } from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

import type { NavItem } from "@/types/navigation-types";

import { route } from "nextjs-routes";

import { isInternalItem } from "@/hooks/useNavs";

type Props = {
  item: NavItem;
  color?: string;
  px?: string | number;
  onClick?: () => void;
} & Partial<LinkProps>;

export const NavLink = ({ item, color, onClick, ...props }: Props) => {
  const isInternalLink = isInternalItem(item);
  const href = isInternalLink ? route(item.nextRoute) : item.url;

  const content = (
    <Link
      href={href}
      target={isInternalLink ? "_self" : "_blank"}
      display="flex"
      borderRadius={{ base: "md", lg: "unset" }}
      aria-label={`${item.text} link`}
      whiteSpace="nowrap"
      onClick={onClick}
      width="full"
      height="full"
      role="group"
      color={color || "neutral.element.secondary"}
      _hover={{ color: "neutral.element.primary" }}
      fontWeight={500}
      fontSize="16px"
      gap={2}
      {...props}
    >
      <span>{item.text}</span>
    </Link>
  );

  return isInternalLink ? (
    <NextLink
      style={{ width: "100%", height: "100%" }}
      href={item.nextRoute as any}
      passHref
      legacyBehavior
    >
      {content}
    </NextLink>
  ) : (
    content
  );
};

const NavLinkChakra = chakra(NavLink, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);

    if (isChakraProp && prop !== "px") {
      return false;
    }

    return true;
  },
});

export default React.memo(NavLinkChakra);

import { compile } from "path-to-regexp";
import axios, { AxiosRequestConfig } from "axios";
import { useCallback } from "react";
import appConfig from "@/../configs/app";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { VaultInfoEntity } from "@/lib/entities/vault.entity";
import { useChain } from "@/hooks/Web3ModalProvider";

export type ArrayElement<T> = T extends Array<infer type> ? type : never;

export const RESOURCES = {
  vault_list: {
    path: "/vaults/all/",
    method: "GET",
  },
  vault_details: {
    path: "/vaults/:vaultAddress",
    method: "GET",
    pathParams: ["vaultAddress" as const],
  },
  vault_tvl: {
    path: "/vaults/tvl",
    method: "GET",
  },
};

export type ResourceName = keyof typeof RESOURCES;

type ResourcePathParamName<Resource extends ResourceName> =
  (typeof RESOURCES)[Resource] extends { pathParams: Array<string> }
    ? ArrayElement<(typeof RESOURCES)[Resource]["pathParams"]>
    : string;

export type ResourcePathParams<Resource extends ResourceName> =
  (typeof RESOURCES)[Resource] extends { pathParams: Array<string> }
    ? Record<ResourcePathParamName<Resource>, string | undefined>
    : never;

export type ResourceResponse<Q extends ResourceName> = Q extends "vault_list"
  ? VaultInfoEntity[]
  : Q extends "vault_details"
    ? VaultInfoEntity
    : Q extends "vault_tvl"
      ? number
      : never;

const buildUrl = <R extends ResourceName>(
  chainId: number,
  resource: R,
  pathParams: ResourcePathParams<R>,
  queryParams?: Record<string, string>
) => {
  const url = new URL(
    `/api/${chainId}/${compile(RESOURCES[resource].path)(pathParams)}`,
    `${appConfig.app_uri}`
  );
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      value !== undefined &&
        value !== "" &&
        url.searchParams.append(key, String(value));
    });
  }

  return url.toString();
};

export const useQueryFetch = () => {
  const { desiredChain } = useChain();

  return useCallback(
    <R extends ResourceName>(
      resource: R,
      options: {
        pathParams?: ResourcePathParams<R>;
        queryParams?: Record<string, string>;
        placeholderData?: ResourceResponse<R>;
        queryOptions?: Omit<
          UseQueryOptions<ResourceResponse<R>>,
          "queryKey" | "queryFn"
        >;
      } & Pick<AxiosRequestConfig, "data">
    ) => {
      const url = buildUrl(
        desiredChain?.chainId,
        resource,
        options.pathParams,
        options.queryParams
      );
      return useQuery({
        queryKey: [RESOURCES[resource].path, options.pathParams],
        placeholderData: options?.queryOptions?.placeholderData,
        queryFn: () =>
          axios(url, {
            method: RESOURCES[resource].method,
            data: options.data,
          }).then((res) => res.data as ResourceResponse<R>),
      });
    },
    [desiredChain]
  );
};

import { instanceToPlain, plainToInstance } from "class-transformer";
import { createSelector, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { VaultInfo } from "@/lib/entities/vault.entity";

export class VaultState {
  vaults: Record<string, Record<string, any>>;
}

const vaultSlice = createSlice({
  name: "vault",
  initialState: {
    vaults: {},
  } as VaultState,
  reducers: {
    // Update single vault
    updateSingleVault: (state, action: PayloadAction<VaultInfo>) => {
      return {
        ...state,
        vaults: {
          ...state.vaults,
          [action.payload.address]: instanceToPlain(action.payload),
        },
      };
    },

    // Update multiple vaults
    updateMultipleVault: (
      state,
      action: PayloadAction<{ data: VaultInfo[]; reset?: boolean }>
    ) => {
      const vaults = action.payload.data.reduce((acc, elm) => {
        if (!elm) return acc;
        return {
          ...acc,
          [elm.address]: instanceToPlain(elm),
        };
      }, {});

      return {
        ...state,
        vaults: action.payload.reset
          ? vaults
          : {
              ...state.vaults,
              ...vaults,
            },
      };
    },
  },
});

export const { updateMultipleVault, updateSingleVault } = vaultSlice.actions;
export default vaultSlice.reducer;

const query = (state: RootState) => state.vaults.vaults;

export const getVaultsQuery = createSelector([query], (vaults) => {
  type IData = Record<string, VaultInfo>;
  return Object.values(vaults).reduce(
    (accum, vault) => ({
      ...accum,
      [vault.address]: plainToInstance(VaultInfo, vault),
    }),
    {} as IData
  ) as IData;
});

export const getSingleVaultQuery = createSelector(
  [query, (_, address: string) => address],
  (vaults, address) => plainToInstance(VaultInfo, vaults[address])
);

import { FC } from "react";
import {
  Image,
  ImageProps,
  Skeleton,
  Text,
  TextProps,
  Tooltip,
  TooltipProps,
} from "@chakra-ui/react";

type Props = TextProps & {
  text: string;
  tooltipDesc: string;
  isLoading?: boolean;
  tooltipProps?: TooltipProps;
  infoIconProps?: ImageProps;
};

export const TextTooltip: FC<Props> = ({
  text,
  isLoading,
  tooltipDesc,
  tooltipProps,
  infoIconProps,
  ...textProps
}) => {
  return (
    <Skeleton isLoaded={!isLoading}>
      <Text
        {...textProps}
        display="flex"
        alignItems="center"
        gap={2}
        justifyContent={textProps.textAlign ?? "flex-start"}
      >
        {text}
        <Tooltip {...tooltipProps} label={tooltipDesc}>
          <Image
            src="/icons/info/light.svg"
            alt="info"
            width="20px"
            height="20px"
            {...infoIconProps}
          />
        </Tooltip>
      </Text>
    </Skeleton>
  );
};

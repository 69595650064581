const colors = {
  brand: {
    primary: "#F2F2F2",
    supper: {
      light: "#282828",
    },
    light: {
      shade: "#E0E0E0",
    },
  },
  neutral: {
    surface: "#060606",
    on: {
      surface: {
        1: "#1A1A1A",
      },
    },
    element: {
      primary: "#FFFFFF",
      secondary: "#C2C2C2",
      tertiary: "#A3A3A3",
      tertiary2: "#969696",
    },
    text: {
      button: "#060606",
    },
    stroke: {
      bold: "#757575",
      light: "#282828",
    },
    2: "#1A1A1A",
  },
  decorative: {
    "02": "#1F3348",
    "04": "#3B315B",
  },
  accent: {
    link: "#0065D1",
    success: "#28CD41",
    warning: "#FF9500",
    info: "#0065D1",
    error: "#E8271C",
  },
};

export default colors;

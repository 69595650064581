import Router from "next/router";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PACIFIC_1_CHAIN } from "@/lib/constants/chain";
import { getCookie, setCookie } from "@/utils/dom";

export class ChainState {
  chainId: number;
}

const ChainReducer = createSlice({
  name: "chain",
  initialState: {
    chainId: (() => {
      return typeof window !== "undefined"
        ? undefined
        : Number(
            getCookie("CHAIN_ID")
              ? getCookie("CHAIN_ID")
              : PACIFIC_1_CHAIN.chainId
          );
    })(),
  } as ChainState,
  reducers: {
    updateChainId: (state, action: PayloadAction<number>) => {
      let chainId = action.payload;
      if (isNaN(chainId)) {
        chainId = Number(getCookie("CHAIN_ID"));
        chainId = !chainId ? PACIFIC_1_CHAIN.chainId : chainId;
      }

      setCookie("CHAIN_ID", chainId.toString());

      const url = new URL(window.location.href);
      url.searchParams.set("chainId", chainId.toString());
      window.history.pushState(null, "", url.toString());

      return {
        ...state,
        chainId,
      };
    },
  },
});

export const { updateChainId } = ChainReducer.actions;
export default ChainReducer.reducer;

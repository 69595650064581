type IFeatureItem = {
  title: string;
  description?: string;
  image?: string;
};

export const AUDIT_REPORT_URL =
  "https://github.com/pitfidev/pit-contracts/blob/main/audits/Verichains%20Public%20Report%20-%20PitFinance.pdf";

export const APP_FEATURES: IFeatureItem[] = [
  {
    title: "Stake",
    description: "Invest your tokens in Pit Vaults",
  },
  {
    title: "Earn",
    description:
      "Pit stakes the tokens on an external, interest-bearing platform and $PIT",
  },
  {
    title: "Auto compound",
    description:
      "Pit regularly and automatically repeats the process, saving you time and fees.",
  },
];

export const SUPPORTED_TOKENS: (IFeatureItem & {
  color: string;
  textColor?: string;
})[] = [
  {
    title: "USDC",
    image: "/icons/tokens/png/usdc.png",
    color: "#C06659",
  },
  {
    title: "USDT",
    image: "/icons/tokens/png/usdt.png",
    color: "#4A8587",
  },
  {
    title: "SEI",
    image: "/icons/tokens/png/sei.png",
    color: "#ECCC74",
  },
  {
    title: "iSEI",
    image: "/icons/tokens/png/isei.png",
    color: "#282828",
    textColor: "#FFFFFF",
  },
];

export type FooterMenuItem = {
  title: string;
  href: string;
  isExternal?: boolean;
};

export type FooterMenuGroup = {
  title: string;
  items: FooterMenuItem[];
};

export type FooterSocialItem = {
  icon: string;
  href: string;
};

export const FOOTER_SOCIAL_ITEMS: FooterSocialItem[] = [
  { icon: "/icons/socials/twitter.svg", href: "https://x.com/pit_finance" },
  { icon: "/icons/socials/telegram.svg", href: "https://t.me/pitfinance" },
  {
    icon: "/icons/socials/github.svg",
    href: "https://github.com/pitfidev/pit-contracts",
  },
];

export const FOOTER_MENU_GROUPS: FooterMenuGroup[] = [
  {
    title: "Products",
    items: [
      { title: "Press Kit", href: "/press-kit" },
      {
        title: "Audit",
        href: AUDIT_REPORT_URL,
        isExternal: true,
      },
      // { title: "FIT tokens", href: "/fit-tokens" },
    ],
  },
  {
    title: "Resources",
    items: [
      { title: "Docs", href: "https://docs.pit.finance/", isExternal: true },
      {
        title: "FAQs",
        href: "https://docs.pit.finance/faqs/general",
        isExternal: true,
      },
      { title: "Contact Us", href: "mailto:contact@pit.finance" },
    ],
  },
  // {
  //   title: "Information",
  //   items: [
  //     { title: "About us", href: "/about" },
  //     { title: "Contact Us", href: "/contact-us" },
  //     { title: "Privacy Notice", href: "/privacy-notice" },
  //     { title: "Terms Of Use", href: "/terms-of-use" },
  //   ],
  // },
];

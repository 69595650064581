import { FC, ReactNode } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

export const Center: FC<
  BoxProps & {
    contentProps?: BoxProps;
    children: ReactNode;
  }
> = ({ children, contentProps, ...props }) => (
  <Box {...props}>
    <Box
      // maxW={{ base: "full ", md: "1366px" }}
      // px={{ base: "16px", md: "24px", xl: "0px" }}
      // mx="auto"
      {...contentProps}
    >
      {children}
    </Box>
  </Box>
);

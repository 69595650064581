import { useChain } from "../Web3ModalProvider";
import { useCallback } from "react";
import { EVM_ADDRESS } from "@/types/web3";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { TransactionMethod } from "@/hooks//useTransaction";
import { useContract } from "./useContract";

export const useWrapSei = () => {
  const { desiredChain } = useChain();
  const { setLocalAmount, writeContract, ...left } = useContract(
    TransactionMethod.Wrap
  );

  const wrap = useCallback(
    (amount: AppNumber) => {
      setLocalAmount(amount);
      writeContract({
        address: desiredChain.nativeToken.wrappedTokenAddress as EVM_ADDRESS,
        abi: [
          {
            name: "deposit",
            type: "function",
            stateMutability: "payable",
            inputs: [],
            outputs: [],
          },
        ],
        functionName: "deposit",
        value: amount.toBigNumber(),
      } as any);
    },
    [desiredChain, writeContract]
  );

  return { ...left, wrap };
};

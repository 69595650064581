import { FC, useEffect } from "react";
import { useRouter } from "next/router";
import { useAppDispatch } from "@/redux/store";
import { updateChainId } from "@/redux/chain.state";

import { useVault } from "@/hooks/vault/useVault";
import { useInitTokens } from "@/hooks/useInitTokens";

export const OnLoadModule: FC = () => {
  const dispatch = useAppDispatch();
  const vaultQueryData = useVault();

  const router = useRouter();

  const { updateTokens } = useInitTokens();

  const handleRouteChange = () => {
    const desiredChainId = router.query.chainId as string;
    dispatch(updateChainId(Number(desiredChainId ?? NaN)));
  };

  useEffect(() => {
    if (vaultQueryData.isPlaceholderData) return;
    updateTokens();
  }, [vaultQueryData]);

  /**
   * Update the chainId in the redux store when the page loads
   * This is necessary because the chainId is stored in the URL
   */
  useEffect(() => {
    const desiredChainId = router.query.chainId as string;
    dispatch(updateChainId(Number(desiredChainId ?? NaN)));
  }, [router?.query?.chainId]);

  /**
   * Update the chainId in the redux store when the route changes
   * This is necessary because the chainId is stored in the URL
   */
  useEffect(() => {
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  return <></>;
};

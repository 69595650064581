import { FC, ReactNode } from "react";
import { Text as CharkaText, Skeleton, TextProps } from "@chakra-ui/react";

type Props = TextProps & {
  isLoading?: boolean;
  children?: ReactNode;
};

export const Text: FC<Props> = ({ children, isLoading, ...rest }) => (
  <Skeleton isLoaded={!isLoading}>
    <CharkaText {...rest}>{children}</CharkaText>
  </Skeleton>
);

import { get } from "radash";

import DevConfig from "../envs/config.dev.json";
import ProdConfig from "../envs/config.prod.json";
import LocalConfig from "../envs/config.local.json";

export const replaceQuotes = (value: string | undefined) =>
  value?.replaceAll("'", '"');

/**
 * Get the value of an environment variable
 * @param path
 * @param defaultValue
 */
export const getEnvValue = (path: string, defaultValue: string = null) => {
  const appEnv = process.env.NEXT_PUBLIC_APP_ENV;
  const config = {
    dev: DevConfig,
    prod: ProdConfig,
    local: LocalConfig,
  }[appEnv];

  return get(config, path, defaultValue);
};

export const parseEnvJson = <DataType>(
  env: string | undefined
): DataType | null => {
  try {
    return JSON.parse(env || "null") as DataType | null;
  } catch (error) {
    return null;
  }
};

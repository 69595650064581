import type { SystemStyleInterpolation } from "@chakra-ui/styled-system";
import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system";

import getDefaultTransitionProps from "../utils/getDefaultTransitionProps";

const baseStyle = defineStyle({
  ...getDefaultTransitionProps(),
});

const variantPrimary = defineStyle(() => {
  return {
    color: "neutral.element.primary",
    fontWeight: 500,
    _hover: {
      textStyle: "underline",
    },
  };
});

const variants: Record<string, SystemStyleInterpolation> = {
  primary: variantPrimary,
};

const defaultProps = {
  variant: "primary",
};

const Link = defineStyleConfig({
  variants,
  defaultProps,
  baseStyle,
});

export default Link;

import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
  UseDisclosureProps,
  Skeleton,
  Flex,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { CurrencyInput } from "./Input/CurrencyInput";
import {
  useGetBalances,
  useSingleBalance,
} from "@/hooks/wallet/BalanceProvider";
import { Web3ActionButton } from "./Web3ActionButton";
import { useWrapSei } from "@/hooks/wallet/useWrapSei";
import { AppNumber } from "@/lib/providers/math/app-number.provider";
import { capitalizeFirstLetter } from "@/utils/dom";
import { useUnwrapSei } from "@/hooks/wallet/useUnwrapSei";
import { TextTooltip } from "./TextTooltip";
import { useChain } from "@/hooks/Web3ModalProvider";

export const WrapNativeTokenModal: FC<UseDisclosureProps> = ({
  onClose,
  isOpen,
}) => {
  const [inputValue, setInputValue] = useState<AppNumber>(undefined);
  const [outputValue, setOutputValue] = useState<AppNumber>(undefined);
  const [error, setError] = useState("");
  const [direction, setDirection] = useState<"wrap" | "unwrap">("wrap");

  const { desiredChain } = useChain();
  const seiBalance = useSingleBalance(
    desiredChain?.nativeToken.contractAddress
  );
  const wrappedSeiBalance = useSingleBalance(
    desiredChain?.nativeToken.wrappedTokenAddress
  );

  const {
    wrap,
    isPending: pendingWrap,
    isSuccess: isSuccessWrap,
  } = useWrapSei();
  const {
    unwrap,
    isPending: pendingUnwrap,
    isSuccess: isSuccessUnwrap,
  } = useUnwrapSei();
  const isPending = direction === "wrap" ? pendingWrap : pendingUnwrap;

  const onChangeInput = useCallback(
    (value: AppNumber) => {
      setInputValue(value);
      setOutputValue(value);

      const balance = direction === "wrap" ? seiBalance : wrappedSeiBalance;
      if (balance.balance.lt(value)) {
        setError("Insufficient balance");
      } else {
        setError("");
      }
    },
    [seiBalance, wrappedSeiBalance, direction]
  );

  const handleWrap = useCallback(async () => {
    const amount = inputValue.getRealTokenAmountWithDecimals(
      seiBalance.tokenInfo.decimals
    );
    if (direction === "wrap") {
      wrap(amount);
    } else {
      unwrap(amount);
    }
  }, [seiBalance, inputValue, direction, wrap]);

  useEffect(() => {
    const balance = direction === "wrap" ? seiBalance : wrappedSeiBalance;
    if (inputValue && balance?.balance?.lt(inputValue)) {
      setError("Insufficient balance");
    } else {
      setError("");
    }
  }, [inputValue, seiBalance, wrappedSeiBalance, direction]);

  useEffect(() => {
    if (isSuccessWrap || isSuccessUnwrap) {
      setInputValue(AppNumber.from(0));
      setOutputValue(AppNumber.from(0));
    }
  }, [isSuccessWrap, isSuccessUnwrap]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="360px" height="auto">
        <ModalBody display="flex" flexDirection="column" gap="28px">
          <ModalHeader>Wrap/Unwrap SEI</ModalHeader>
          <ModalCloseButton />
          <Flex flexDirection="column" gap="12px">
            <CurrencyInput
              headerInfo
              hideEstimatedValue
              value={inputValue}
              onValueChange={(val) => onChangeInput(val)}
              isLoading={false}
              tokenBalance={
                direction === "wrap" ? seiBalance : wrappedSeiBalance
              }
              self={true}
              error={error}
            />
            <Skeleton
              isLoaded={!false}
              display="flex"
              mx="auto"
              width="40px"
              height="40px"
              borderRadius="40px"
              bgColor="neutral.stroke.light"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={() =>
                setDirection(direction === "wrap" ? "unwrap" : "wrap")
              }
            >
              <Image
                src="/icons/arrow/down/light.svg"
                alt="Arrow down light"
                width="24px"
                height="24px"
              />
            </Skeleton>
            <CurrencyInput
              headerInfo
              hideEstimatedValue
              onValueChange={(val) => {}}
              tokenBalance={
                direction !== "wrap" ? seiBalance : wrappedSeiBalance
              }
              value={outputValue}
              inputContentProps={{
                isDisabled: true,
              }}
            />
          </Flex>
          <TextTooltip
            size="sm"
            colorScheme="secondary"
            textAlign="center"
            my={2}
            text="Gas fee < $0.01"
            tooltipDesc="This gas calculation is only an estimation. Your wallet will set the price of the transaction. You can modify the gas settings directly from your wallet provider."
          />
          <Web3ActionButton
            mt={2}
            colorScheme="white"
            onClick={() => handleWrap()}
            width="full"
            containerProps={{ width: "full" }}
            isLoadingButton={isPending}
            loadingText={direction === "wrap" ? "Wrapping" : "Unwrapping"}
            isDisabled={
              error !== "" ||
              inputValue === undefined ||
              inputValue.toNumber() === 0
            }
          >
            {!inputValue
              ? "Enter an amount"
              : `${capitalizeFirstLetter(direction)} ${seiBalance?.tokenInfo?.symbol}`}
          </Web3ActionButton>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

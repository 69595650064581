import {
  Button,
  Flex,
  FlexProps,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { memo } from "react";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "../PopoverModal";
import HeaderProfileMenuContent from "./HeaderProfileMenuContent";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import { AddressEntry } from "@/ui/Components/AddressEntry";
import { WalletAvatar } from "@/ui/Components/WalletAvatar";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { WrapNativeTokenModal } from "../WrapNativeTokenModal";
import HeaderHamburgerButton from "./HeaderHamburgerButton";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { onWrapModal, selectIsWrapModalOpen } from "@/redux/ui";

type Props = {
  isOpenDropdown: boolean;
  onToggleDropdown: () => void;
  onClick?: FlexProps["onClick"];
};

const HeaderProfileMenu = ({
  isOpenDropdown,
  onClick,
  onToggleDropdown,
}: Props) => {
  const { open: openWeb3Modal } = useWeb3Modal();
  const { rpcSigner } = useSigner();

  const isWrapModalOpen = useAppSelector(selectIsWrapModalOpen);
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure();

  const isLoading = false;
  const account = true;

  return (
    <>
      <PopoverModal
        isOpen={isOpen}
        onClose={onClose}
        isFullscreen={false}
        openDirection="zoom"
        borderRadius="full"
      >
        <PopoverModalTrigger borderRadius="full" display="flex" gap="12px">
          {rpcSigner?.address ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              gap="10px"
              padding="12px"
              borderRadius="12px"
              height="40px"
              border="1px solid"
              borderColor="brand.primary"
              bg={isOpen ? "brand.supper.light" : "neutral.on.surface.1"}
              _hover={{ bg: "brand.supper.light" }}
              cursor="pointer"
              onClick={(e) => {
                onToggle();
                onClick?.(e);
              }}
            >
              <WalletAvatar
                seed={rpcSigner?.address}
                alt="Picture of the author"
                width="24px"
                height="24px"
              />
              <AddressEntry
                noCopy
                textProps={{ size: "md" }}
                truncation="tail"
                entity={{
                  address: rpcSigner?.address,
                }}
              />
              <Image
                alt="Chevron down"
                width="24px"
                height="24px"
                src={
                  {
                    true: "/icons/chevron/up/light.svg",
                    false: "/icons/chevron/down/light.svg",
                  }[`${isOpen}`]
                }
              />
            </Flex>
          ) : (
            <Button
              colorScheme="white"
              loadingText="Signing in..."
              isLoading={isLoading}
              onClick={() => {
                openWeb3Modal({ view: "Wallet" } as any)
                  .then(() => console.log("resolved"))
                  .catch(() => console.log("rejected"));
              }}
            >
              Connect wallet
            </Button>
          )}
          <HeaderHamburgerButton
            display={{ lg: "none" }}
            onClick={() => onToggleDropdown()}
            isOpen={isOpenDropdown}
          ></HeaderHamburgerButton>
        </PopoverModalTrigger>
        <PopoverModalContent
          right={0}
          top={{ base: "calc(100% + 5px)", lg: "cacl(100% + 10px)" }}
          borderRadius="12px"
          bgColor="neutral.2"
          border="1px solid"
          borderColor="neutral.stroke.bold"
          boxShadow="0px 32px 60px -24px #000"
          width="343px"
          padding="24px"
          overflow="hidden"
        >
          {account && (
            <HeaderProfileMenuContent
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
              wrapModalProps={{
                isOpen: isWrapModalOpen,
                onOpen: () => dispatch(onWrapModal(true)),
                onClose: () => dispatch(onWrapModal(false)),
              }}
            />
          )}
        </PopoverModalContent>
      </PopoverModal>
      {isWrapModalOpen && (
        <WrapNativeTokenModal
          {...{
            isOpen: isWrapModalOpen,
            onOpen: () => dispatch(onWrapModal(true)),
            onClose: () => dispatch(onWrapModal(false)),
          }}
        />
      )}
    </>
  );
};

export default memo(HeaderProfileMenu);

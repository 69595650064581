import {
  AppNumber,
  LARGE_NUMBER,
  ROUND_DECIMAL_PLACES,
} from "@/lib/providers/math/app-number.provider";
import {
  TextProps,
  Tooltip,
  Text,
  TooltipProps,
  Skeleton,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = TextProps & {
  toolTipProps?: TooltipProps;
  isLoading?: boolean;
  value: AppNumber;
  content?: string;
  roundData?: {
    minimumToFormat: number; //10000,
    precisionRound: number; // ROUND_DECIMAL_PLACES
  };
};

export const NumberText: FC<Props> = ({
  value,
  toolTipProps,
  isLoading,
  content,
  roundData = {
    minimumToFormat: LARGE_NUMBER.kk,
    precisionRound: ROUND_DECIMAL_PLACES,
  },
  ...props
}) => {
  const realValue = value ? value.getValue().toString() : 0;
  const roundedValue = value
    ? value.getDisplayedString(
        roundData.minimumToFormat,
        roundData.precisionRound
      )
    : 0;

  const isHasTooltip = realValue !== roundedValue;

  return (
    <Tooltip label={isHasTooltip ? realValue : ""} {...toolTipProps}>
      <Skeleton isLoaded={!isLoading}>
        <Text {...props}>
          {roundedValue}
          {content && ` ${content}`}
        </Text>
      </Skeleton>
    </Tooltip>
  );
};

"use client";

import { FC, ReactNode, createContext, useMemo } from "react";

import { http } from "viem";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { walletConnect, injected } from "wagmi/connectors";
import {
  State,
  createConfig,
  WagmiProvider,
  cookieStorage,
  createStorage,
  CreateConnectorFn,
} from "wagmi";

import * as CHAIN_CONFIGS from "@/lib/constants/chain";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ChainConfig } from "@/lib/entities/chain.entity";
import { useAppSelector } from "@/redux/store";
import { getCookie } from "@/utils/dom";
import { useRouter } from "next/router";
import appConfig from "configs/app";

if (!appConfig.web3_connect_project_id) {
  throw new Error("WEB3_CONNECTOR_PROJECT_ID is not set");
}

const queryClient = new QueryClient();
type IProviderContext = {
  desiredChain: ChainConfig;
};
export const ProviderContext = createContext<IProviderContext>(null);
export const Web3ModalProvider: FC<{
  children: ReactNode;
  initialState?: State;
}> = ({ children, initialState }) => {
  const { desiredChain } = useChain();
  const config = desiredChain?.config;
  const transports = config?.id
    ? { [config?.id]: http(config?.rpcUrls.default.http[0]) }
    : {};

  const metadata = {
    name: appConfig.meta_data.title,
    description: appConfig.meta_data.description,
    icons: [appConfig.meta_data.icon],
    url: appConfig.app_uri,
  };

  const connectors: CreateConnectorFn[] = [
    walletConnect({
      projectId: appConfig.web3_connect_project_id,
      metadata,
      showQrModal: false,
    }),
    injected({ shimDisconnect: true }),
  ];

  const wagmiConfig = useMemo(() => {
    return createConfig({
      chains: [config],
      connectors: connectors,
      transports,
      ssr: true,
      storage: createStorage({
        storage: cookieStorage,
      }),
    });
  }, [config]);

  createWeb3Modal({
    wagmiConfig,
    projectId: appConfig.web3_connect_project_id,
    includeWalletIds: [
      // "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
      "971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709", // okx
      "8a0ee50d1f22f6651afcae7eb4253e52a3310b90af5daef78a8c4929a9bb99d4", // binance
    ],
  });

  return (
    <ProviderContext.Provider value={{ desiredChain }}>
      <WagmiProvider config={wagmiConfig} initialState={initialState}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </WagmiProvider>
    </ProviderContext.Provider>
  );
};

const chainList = [
  CHAIN_CONFIGS.PACIFIC_1_CHAIN,
  CHAIN_CONFIGS.ARCTIC_1_CHAIN,
  CHAIN_CONFIGS.ATLANTIC_2_CHAIN,
];

export const useChain = () => {
  const router = useRouter();
  const queryChainId = router?.query?.chainId as string;
  const { chainId } = useAppSelector((state) => state.chain);
  let chain = chainList.find(
    (chain) => chain.chainId === (queryChainId ? Number(queryChainId) : chainId)
  );
  let desiredChain = chain;

  if (!chain) {
    desiredChain = chainList.find(
      (chain) =>
        chain.chainId ===
        (getCookie("CHAIN_ID")
          ? Number(getCookie("CHAIN_ID"))
          : CHAIN_CONFIGS.PACIFIC_1_CHAIN.chainId)
    );
  }

  return {
    desiredChain,
  };
};

import { FC } from "react";
import { Box, BoxProps, Image } from "@chakra-ui/react";

/**
 * @dev This component displays a divider to separate components with an optional blur effect at the ends.
 * @prop {className} Override style by passing className.
 * @prop {colorScheme} Customize the color of the divider.
 * @prop {thickness} Customize the thickness of the divider.
 * @prop {variant} Choose the border style (solid, dashed, dotted).
 */
interface DividerProps extends BoxProps {
  colorScheme?: string;
  thickness?: string;
  variant?: "solid" | "dashed" | "dotted";
  blurEnds?: boolean;
}

export const Divider: FC<DividerProps> = ({
  colorScheme = "#757575",
  thickness = "1px",
  variant = "dashed",
  blurEnds = true,
  ...props
}) => {
  if (blurEnds) {
    return <Image src="/icons/divider.png" w="full" height="1px" {...props} />;
  }

  const borderStyle = blurEnds
    ? `1px dashed transparent`
    : `${thickness} ${variant} ${colorScheme}`;

  return <Box w="full" height={thickness} border={borderStyle} {...props} />;
};

import { toast, ToastType } from "@/ui/toast";

export const useClipboard = () => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast({ title: "Copied to clipboard", type: ToastType.info });
      },
      () => {
        toast({
          title: "Failed to copy to clipboard",
          type: ToastType.error,
        });
      }
    );
  };

  return {
    copyToClipboard,
  };
};

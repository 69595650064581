import { FC } from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const AVATAR_HOST_URL = "https://api.dicebear.com/9.x/bottts-neutral/svg?seed=";

type Props = {
  seed: string;
} & ImageProps;

export const WalletAvatar: FC<Props> = ({ seed, ...props }) => {
  return (
    <Image
      src={`${AVATAR_HOST_URL}${seed}`}
      alt="Avatar"
      {...props}
      borderRadius="full"
    />
  );
};

import { createContext, FC, ReactNode, useContext, useMemo } from "react";
import { useWalletClient, useAccount } from "wagmi";
import { BrowserProvider, JsonRpcProvider, JsonRpcSigner } from "ethers";
import { useChain } from "../Web3ModalProvider";
import { SignerTokenService } from "@/lib/services/signer-token.service";
import { useAppSelector } from "@/redux/store";
import { getWhitelistedTokens } from "@/redux/whitelisted-token.state";

const walletClientToSigner = (client: any) => {
  const { account, chain, transport } = client;
  const provider = new BrowserProvider(transport as any, {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  });

  return new JsonRpcSigner(provider, account.address);
};

export const WalletContext = createContext<{
  rpcSigner: JsonRpcSigner;
  rpcProvider: JsonRpcProvider;
  service: {
    signerTokenService: SignerTokenService;
  };
}>(null);

export const SignerProvider: FC<{ children: ReactNode }> = (props) => {
  const client = useWalletClient();
  const { desiredChain } = useChain();
  const { isConnected } = useAccount();
  const whitelistedTokens = useAppSelector(getWhitelistedTokens);

  const rpcSigner = useMemo(() => {
    if (!isConnected) return null;
    return client?.data ? walletClientToSigner(client.data) : null;
  }, [client, isConnected]);

  const rpcProvider = useMemo(
    () => new JsonRpcProvider(desiredChain.rpcUrl),
    [desiredChain]
  );

  return (
    <WalletContext.Provider
      value={{
        rpcSigner,
        rpcProvider,
        service: {
          signerTokenService: new SignerTokenService(
            Object.values(whitelistedTokens)
          ),
        },
      }}
    >
      {props.children}
    </WalletContext.Provider>
  );
};

export const useSigner = () => {
  const context = useContext(WalletContext);
  if (!context) {
    throw new Error("Must be in provider");
  }
  return context;
};

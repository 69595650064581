import { FC, ReactNode } from "react";
import { Flex, FlexProps } from "@chakra-ui/react";

type Props = FlexProps & { children: ReactNode; backgroundContent?: ReactNode };

export const LayoutSectionCard: FC<Props> = ({
  children,
  backgroundContent,
  ...flexProps
}) => (
  <>
    <Flex w="full" position="relative" overflow="hidden">
      {backgroundContent && backgroundContent}
      <Flex
        mx="auto"
        gap="40px"
        flexDirection="column"
        py={{ base: "40px", lg: "120px" }}
        px={{ base: "16px", lg: "24px", xl: "40px" }}
        w={{ base: "100%", xl: "1366px" }}
        {...flexProps}
      >
        {children}
      </Flex>
    </Flex>
  </>
);

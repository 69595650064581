import { useChain } from "@/hooks/Web3ModalProvider";
import { useCallback } from "react";
import { TransactionMethod } from "@/hooks//useTransaction";
import { ethers } from "ethers";

import TokenABI from "@/abi/abi.token.json";
import { useContract } from "./useContract";

export const useApproveToken = () => {
  const { desiredChain } = useChain();
  const { hash, isError, isIdle, isPending, isSuccess, writeContract } =
    useContract(TransactionMethod.Approve);

  const approve = useCallback(
    (props: { tokenAddress: string; spenderAddress: string }) => {
      writeContract({
        address: props.tokenAddress,
        abi: TokenABI,
        functionName: "approve",
        args: [props.spenderAddress, ethers.MaxInt256],
      } as any);
    },
    [desiredChain, writeContract]
  );

  return { hash, isPending, isSuccess, isError, isIdle, approve };
};

import Alert from "./Alert/Alert";
import Badge from "./Badge";
import Button from "./Button/Button";
import Checkbox from "./Checkbox";
import Code from "./Code";
import Divider from "./Divider";
import Drawer from "./Drawer";
import FormLabel from "./FormLabel";
import Heading from "./Heading";
import Input from "./Input";
import Link from "./Link";
import Menu from "./Menu";
import Modal from "./Modal";
import Popover from "./Popover";
import Skeleton from "./Skeleton";
import Tag from "./Tag/Tag";
import Text from "./Text";
import Tooltip from "./Tooltip/Tooltip";

const components = {
  Alert,
  Badge,
  Button,
  Code,
  Checkbox,
  Drawer,
  Divider,
  Heading,
  Input,
  FormLabel,
  Link,
  Menu,
  Modal,
  Popover,
  Skeleton,
  Tag,
  Text,
  Tooltip,
};

export default components;

import { FC, useMemo } from "react";
import {
  Text,
  Flex,
  Image,
  FlexProps,
  TextProps,
  Button,
  Skeleton,
  ButtonProps,
  Tooltip,
  useBreakpoint,
} from "@chakra-ui/react";
import { useClipboard } from "@/hooks/useClipboard";
import { useChain } from "@/hooks/Web3ModalProvider";

type Truncation = "constant" | "dynamic" | "tail" | "none";

type Props = {
  noCopy?: boolean;
  isLoading?: boolean;
  variant?: "link" | "text";
  type?: "address" | "token" | "tx";
  entity: {
    address: string;
    name?: string;
  };

  containerProps?: FlexProps;
  contentProps?: FlexProps;
  textProps?: TextProps;
  copyIconProps?: ButtonProps;

  truncation?: Truncation;
  truncationLength?: number;
  truncationData?: {
    headLength: number;
    tailLength: number;
  };
};

export const AddressEntry: FC<Props> = ({
  noCopy,
  isLoading,
  copyIconProps,
  truncationLength,
  truncationData,
  type = "tx",
  variant = "text",
  truncation = "constant",
  ...props
}) => {
  const { copyToClipboard } = useClipboard();
  const { desiredChain } = useChain();
  const breakPoint = useBreakpoint();

  const text = props.entity.name || props.entity.address || "";

  const externalLinkUrl = useMemo(() => {
    switch (type) {
      case "address":
        return desiredChain?.addressUrl(props.entity.address);
      case "token":
        return desiredChain?.tokenUrl(props.entity.address);
      case "tx":
      default:
        return desiredChain?.txUrl(props.entity.address);
    }
  }, [type, desiredChain, props.entity.address]);

  const truncationText = useMemo(() => {
    switch (truncation) {
      case "constant":
        return text.length > truncationLength
          ? text.slice(0, truncationLength)
          : text;
      case "dynamic":
        return text.length > truncationLength
          ? text.slice(0, truncationLength)
          : text;
      case "tail":
        const tailLength = truncationLength ?? 12;
        if (text.length > tailLength) {
          if (truncationData) {
            return (
              text.slice(0, truncationData.headLength) +
              "..." +
              text.slice(-truncationData.tailLength)
            );
          }

          let headLength, tailLength;
          switch (breakPoint) {
            case "base":
            case "sm":
              headLength = 4;
              tailLength = 3;
              break;
            case "lg":
            case "xl":
            default:
              headLength = 6;
              tailLength = 4;
              break;
          }

          return text.slice(0, headLength) + "..." + text.slice(-tailLength);
        }
        return text;
      case "none":
      default:
        return text;
    }
  }, [text, breakPoint, truncation, truncationData, truncationLength]);

  const textContent = useMemo(() => {
    const commonProps: TextProps = {
      size: "md",
      wordBreak: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      float: "left",
    };

    if (variant === "link") {
      return (
        <Text
          label={props.entity.address}
          {...commonProps}
          cursor="pointer"
          color="accent.link"
          _hover={{ textDecoration: "underline" }}
          onClick={() => global.window.open(externalLinkUrl, "_blank")}
          {...props.textProps}
        >
          {truncationText}
        </Text>
      );
    }

    return (
      <Text {...commonProps} {...props.textProps}>
        {truncationText}
      </Text>
    );
  }, [truncationText, props.textProps, props.entity, breakPoint]);

  return (
    <Skeleton isLoaded={!isLoading} {...props.containerProps}>
      <Flex
        gap={1}
        cursor="pointer"
        alignItems="center"
        {...props.contentProps}
      >
        <Tooltip label={props.entity.address}>{textContent}</Tooltip>
        {noCopy !== true && (
          <Button
            // w={{ base: "20%", md: "8%" }}
            w="auto"
            padding={0}
            float="left"
            variant="none"
            onClick={() => {
              copyToClipboard(props.entity.address);
            }}
            {...copyIconProps}
          >
            <Image
              src="/icons/copy/light.svg"
              alt="Copy address"
              width="24px"
              height="24px"
            />
          </Button>
        )}
      </Flex>
    </Skeleton>
  );
};

const breakpoints = {
  sm: "375px",
  md: "768px",
  lg: "1024px",
  "2lg": "1200px",
  xl: "1366px",
  "2xl": "3000px",
};

export default breakpoints;

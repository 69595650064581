import { getEnvValue } from "./utils";

const app = Object.freeze({
  web3_connect_project_id: getEnvValue("WEB3_CONNECTOR_PROJECT_ID"),
  meta_data: {
    title: getEnvValue("META_DATA_TITLE"),
    description: getEnvValue("META_DATA_DESCRIPTION"),
  },

  api_uri: [
    `${getEnvValue("API.PROTOCOL")}://`,
    `${getEnvValue("API.HOST")}`,
    `${getEnvValue("API.PORT") ? `:${getEnvValue("API.PORT")}` : ""}`,
  ].join(""),

  app_uri: [
    `${getEnvValue("APP.PROTOCOL")}://`,
    `${getEnvValue("APP.HOST")}`,
    `${getEnvValue("APP.PORT") ? `:${getEnvValue("APP.PORT")}` : ""}`,
  ].join(""),
});

export default {
  ...app,
  meta_data: {
    ...app.meta_data,
    icon: `${app.app_uri}/assets/icons/favicon-per.png`,
  },
};

import {
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Text } from "@/ui/Components/Text";
import { FC, useEffect, useState } from "react";
import { AddressEntry } from "./AddressEntry";
import { useSigner } from "@/hooks/wallet/SignerProvider";
import {
  PopoverModal,
  PopoverModalContent,
  PopoverModalTrigger,
} from "./PopoverModal";
import { useDisconnect } from "wagmi";
import { useChain } from "@/hooks/Web3ModalProvider";
import { WalletAvatar } from "./WalletAvatar";

export const LinkAccountModal: FC = () => {
  const { rpcSigner } = useSigner();
  const { disconnect } = useDisconnect();
  const [isAssociated, setIsAssociated] = useState(true);
  const { desiredChain } = useChain();

  const walletPopoverProps = useDisclosure();

  useEffect(() => {
    if (!rpcSigner?.address || !desiredChain) return;
    fetch(desiredChain.rpcUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: 1,
        jsonrpc: "2.0",
        method: "sei_getSeiAddress",
        params: [rpcSigner.address],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.error) {
          setIsAssociated(false);
        } else {
          setIsAssociated(true);
        }
      })
      .catch((err) => console.warn(err));
  }, [rpcSigner, desiredChain]);

  const isOpen = rpcSigner?.address && !isAssociated;
  if (!isOpen) return null;
  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent width="360px" height="auto">
        <ModalBody display="flex" flexDirection="column" gap="24px">
          <Image
            src="/images/associate-prompt.png"
            alt="Link account"
            width="200px"
            height="200px"
            margin="0 auto"
          />
          <Text textAlign="center" size="3xl">
            Link Your Address
          </Text>
          <PopoverModal
            isOpen={walletPopoverProps.isOpen}
            onClose={walletPopoverProps.onClose}
            isFullscreen={false}
            openDirection="zoom"
            borderRadius="full"
            width="220px"
            mx="auto"
          >
            <PopoverModalTrigger borderRadius="full">
              <Flex
                alignItems="center"
                justifyContent="center"
                gap="10px"
                padding="12px"
                borderRadius="12px"
                border="1px solid"
                borderColor="brand.primary"
                bg={isOpen ? "brand.supper.light" : "neutral.on.surface.1"}
                _hover={{ bg: "brand.supper.light" }}
                cursor="pointer"
                height="40px"
                width="220px"
                mx="auto"
                onClick={() => walletPopoverProps.onToggle()}
              >
                <WalletAvatar
                  seed={rpcSigner?.address}
                  alt="Picture of the author"
                  width="24px"
                  height="24px"
                />
                <AddressEntry
                  noCopy
                  textProps={{ size: "md" }}
                  truncation="tail"
                  entity={{
                    address: rpcSigner?.address,
                  }}
                />
                <Image
                  src={
                    walletPopoverProps.isOpen
                      ? "/icons/chevron/up/light.svg"
                      : "/icons/chevron/down/light.svg"
                  }
                  alt="Chevron down"
                  width="24px"
                  height="24px"
                />
              </Flex>
            </PopoverModalTrigger>
            <PopoverModalContent
              borderRadius="12px"
              bgColor="neutral.2"
              border="1px solid"
              borderColor="neutral.stroke.bold"
              boxShadow="0px 32px 60px -24px #000"
              width="220px"
              height="40px"
              px="24px"
              py="12px"
              overflow="hidden"
              alignItems="center"
              display="flex"
              flexDirection="row"
            >
              <Flex
                gap={2}
                cursor="pointer"
                width="full"
                alignItems="center"
                onClick={() => {
                  walletPopoverProps.onClose();
                  disconnect();
                }}
              >
                <Image
                  src="/icons/disconnect.svg"
                  alt="wrap"
                  width="24px"
                  height="24px"
                />
                <Text color="accent.error">Disconnect</Text>
              </Flex>
            </PopoverModalContent>
          </PopoverModal>
          <Text size="sm" colorScheme="secondary" textAlign="center">
            To use apps on SEI EVM, you MUST link your address. This will enable
            your EVM address to receive native SEI tokens. Please refresh the
            page once the address is linked.
          </Text>
          <Button
            gap="10px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            colorScheme="white"
            onClick={() =>
              typeof global?.window?.open === "function" &&
              global.window.open("https://app.sei.io")
            }
          >
            Link now
            <Image
              src="/icons/share/dark.svg"
              alt="External link"
              sizes="24px 24px"
            />
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

import {
  NavGroupItem,
  NavItem,
  NavItemInternal,
} from "@/types/navigation-types";
import { useRouter } from "next/router";
import { useMemo } from "react";

export function isGroupItem(
  item: NavItem | NavGroupItem
): item is NavGroupItem {
  return "subItems" in item;
}

export function isInternalItem(item: NavItem): item is NavItemInternal {
  return "nextRoute" in item;
}

export const useNav = () => {
  const router = useRouter();

  return useMemo(() => {
    const navItems: NavItem[] = [
      {
        text: "Home",
        nextRoute: { pathname: "/" },
        isActive: router.pathname === "/",
      },
      {
        text: "Vaults",
        nextRoute: { pathname: "/vaults" },
        isActive:
          router.pathname === "/vaults" || router.pathname === "/vaults/[id]",
      },
      // {
      //   text: "About",
      //   nextRoute: { pathname: "/about" },
      //   isActive: router.pathname === "/about",
      // },
    ];

    return {
      navItems,
    };
  }, [router]);
};

import { TokenBalance } from "@/lib/entities/balance.entity";
import { Flex, Image, Skeleton, Text } from "@chakra-ui/react";
import { FC } from "react";
import { NumberText } from "@/ui/Components/NumberText";
import { useBalances } from "@/hooks/wallet/BalanceProvider";

export const HeaderProfileMenuBalances: FC = () => {
  const { balances, isFetching } = useBalances();
  if (!balances) return null;

  return (
    <Flex flexDirection="column" gap="16px">
      {Object.entries(balances)
        .filter(([_, { tokenInfo }]) => !tokenInfo.isPitToken)
        .map(([contractAddress, balance]: [string, TokenBalance]) => (
          <Flex
            key={`balance-item-${contractAddress}-${Math.random()}`}
            alignItems="center"
            justifyContent="space-between"
          >
            <Flex alignItems="center" gap="12px">
              <Image
                src={balance.tokenInfo.logo}
                alt={balance.tokenInfo.symbol}
                width="24px"
                height="24px"
              />
              <Flex gap="2px">
                <Text size="md" fontWeight="bold">
                  {balance.tokenInfo.symbol}
                </Text>
                <Text size="md" color="neutral.element.tertiary2">
                  {`(${balance.tokenInfo.name})`}
                </Text>
              </Flex>
            </Flex>
            <Skeleton isLoaded={!isFetching}>
              <NumberText size="md" fontWeight="bold" value={balance.balance} />
            </Skeleton>
          </Flex>
        ))}
    </Flex>
  );
};

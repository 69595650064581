import { Box } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Header } from "@/ui/Components/Header/Header";
import { Center } from "@/ui/Components/Center";
import { Footer } from "../Footer";
import { LinkAccountModal } from "../LinkAccountModal";

export const RegularPageLayout: FC<{ children: ReactNode }> = ({
  children,
}) => (
  <Box minHeight="100vh" position="relative" overflow="hidden">
    <Box zIndex={2} position="relative">
      <Header />
      <Center minHeight="80vh">{children}</Center>
      <Footer />
    </Box>
    <LinkAccountModal />
  </Box>
);
